import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import tronBull from "../../images/academy/TRON Bull holding magnify glass.png";
import tronBooks from "../../images/academy/bookshelves.png";

const Academy = ({data}) => {

    let partners = data.allWpAcademyPartner.edges;


    return (
        <div className="tron-academy">
            <Helmet>
                <title>Academy Program | TRONDAO</title>
                <meta name="description"
                      content="Industry-leading curriculum provides a comprehensive blockchain education for technology leaders. We empower students to change the world with Blockchain!"/>
                <meta property="og:description"
                      content="Industry-leading curriculum provides a comprehensive blockchain education for technology leaders. We empower students to change the world with Blockchain!"/>
                <meta property="og:title" content="Academy Program | TRONDAO"/>
            </Helmet>
            <div className="row no-gutters tron-academy-top">
                <div className="col-12 text-center">
                    <h1 className="text-uppercase">TRON Academy</h1>
                    <h2>Working with future minds for the future</h2>
                    <div className="d-flex w-100 justify-content-center mt-3">
                        <a href="https://docs.google.com/forms/d/1rUl5a-LCIMvPoP-F6SMuCXSG-iOHMK2nrxkhGDis1HA/" id="tron-academy-research-cta-top" target="_blank" rel="noopenner noreferrer">
                            <button>Join TRON Academy</button>
                        </a>
                    </div>
                    <img className="img-fluid" src={tronBooks} alt="tron-bulls-bookshelves"/>
                </div>
            </div>
            <div className="row no-gutters tron-academy-middle">
                <div className="col-12 text-center justify-content-center">
                    <h3 className="text-uppercase">Our Mission For TRON Academy</h3>
                    <div className="d-flex w-100 justify-content-center">
                        <h4>TRON Academy's industry-leading curriculum provides a comprehensive and innovative
                            blockchain education for the next generation of technology leaders. With an inspiring
                            learning environment, we empower students to change the world with Blockchain!</h4>
                    </div>
                </div>
            </div>
            <div className="tron-academy-partners-background">
                <div className="row no-gutters tron-academy-partners my-5">
                    <div className="col-12 text-center justify-content-center">
                        <h3 className="text-uppercase">Partnered Schools</h3>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center my-5">
                    <div className="tron-academy-partners-card-section d-flex flex-wrap justify-content-center">
                        {
                            partners.length > 0 && partners.map((partner => {
                                return <div className="tron-academy-partners-card-wrapper">
                                    <div
                                    className="tron-academy-partners-card justify-content-center text-center align-items-center d-flex"
                                    key={"key-" + partner.node.title}>
                                    <img className="img-fluid"
                                         src={partner.node.academy_partner_info.partnerLogo.sourceUrl}
                                         alt={partner.node.title}/>
                                </div>
                                <h4>{partner.node.title}</h4>
                                </div>
                            }))
                        }

                    </div>
                </div>
                <div className="col-12">
                    <p className="text-center">More to come!</p>
                </div>
            </div>
            <div className="row no-gutters tron-academy-research">
                <div className="col-lg-5 col-12 offset-0 offset-lg-1 px-5">
                    <div className="text-uppercase tron-academy-research-text">TRON Research</div>
                    <div className="tron-academy-research-background"/>
                    <p>TRON Research is the place to submit your project studies and reports relating to the
                        decentralization of the internet via blockchain technology. TRON's large community guarantees
                        your work will be seen and interacted with others, and is the place to make a name for yourself
                        as a researcher. Our mission is to provide an open platform for researchers to share their
                        findings with the community, and help further development of a truly decentralized internet.</p>
                    <a href="https://docs.google.com/forms/d/1rUl5a-LCIMvPoP-F6SMuCXSG-iOHMK2nrxkhGDis1HA/" id="tron-academy-research-cta" target="_blank" rel="noopenner noreferrer">
                        <button>Join TRON Academy</button>
                    </a>
                </div>
                <div className="col-lg-6 col-12">
                    <img src={tronBull} className="img-fluid" alt="tron-bull-magnify-glass"/>
                </div>
            </div>
        </div>

    );
};


export default Academy;

export const pageQuery = graphql`
query {
  allWpAcademyPartner(sort: {order: ASC, fields: academy_partner_info___order}) {
    edges {
      node {
        title
        academy_partner_info {
          partnerLogo {
                sourceUrl
          }
          order
        }
      }
    }
  }
}
`
